// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://mikrotreenit.sapartners.site/backend/api/v1/',
  imageUrl: 'https://mikrotreenit.sapartners.site/backend/public/images/',
  firebaseConfig : {
    apiKey: "AIzaSyBzsDCFP_uSD7vk3d2DfXW5elieVOp2A_w",
    authDomain: "mikrotreenit.firebaseapp.com",
    projectId: "mikrotreenit",
    storageBucket: "mikrotreenit.appspot.com",
    messagingSenderId: "162978550014",
    appId: "1:162978550014:web:76dcae26602095364695af",
    measurementId: "G-WTFTRBM577"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
