import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	constructor(private http: HttpClient) {}

	post(serviceName: string, data: any) {
		const headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');
		const options = { headers: headers, withCredintials: false };
		const url = environment.apiUrl + serviceName;

		return this.http.post(url, JSON.stringify(data), options);
	}
	upload(serviceName: string, data: any) {
		var myFormData = new FormData();
		myFormData.append('file', data);
		const headers = new HttpHeaders();
  	 	headers.append('Content-Type', 'multipart/form-data');
      	headers.append('Accept', 'application/json');
		const options = { headers: headers, withCredintials: false };
		const url = environment.apiUrl + serviceName;

		return this.http.post(url,myFormData,options);
	}

	get(serviceName:string,data:any){
		const url = environment.apiUrl + serviceName;
		return this.http.get(url);	
	}

	getExternal(url:string,data:any){
		return this.http.get(url);	
	}
}