import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AuthService } from './services/auth.service';
import { ThemeSwitcherService } from './services/themeswitcher.service';
import { AnalyticsService } from './services/analytics.service';
import { Insomnia } from '@ionic-native/insomnia/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { Badge } from '@ionic-native/badge/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, ReactiveFormsModule, FormsModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, IonicStorageModule.forRoot()],
  providers: [FirebaseAnalytics,InAppBrowser,Badge,FCM,AuthService, Insomnia,AnalyticsService, ThemeSwitcherService, StatusBar, SplashScreen, HttpClientModule, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, OneSignal],
  bootstrap: [AppComponent],
})
export class AppModule {}
