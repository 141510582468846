import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { AuthConstants } from '../config/auth-constants';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData$ = new BehaviorSubject<any>([]);
  authenticationState = new BehaviorSubject(false);
  
  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
    private router: Router,
    private storage: Storage, 
    private plt: Platform) {}
  
  getUserData() {
    this.storageService.get(AuthConstants.AUTH).then(res => {
    this.userData$.next(res);
    });
  }

  gettags(){
  	return this.httpService.get('gettags',null);
  }
  getcategories(){
    return this.httpService.get('getcategories',null);
  }
  getominaisuudet(){
    return this.httpService.get('getominaisuudet',null);
  }
  getcompanydetail(id){
    return this.httpService.get('getcompanydetail/'+id,null);
  }
  getuserByemail(email){
   return this.httpService.get('getuserbyemail/'+email,null); 
  }
  getbookmarked(userid){
    return this.httpService.get('getfavouritetrainings/'+userid,null);
  }
  gettrainings(postData){
    return this.httpService.post('trainings', postData);
  }
  getdeletednotification(userid){
    return this.httpService.get('getdeletednotification/'+userid,null);
  }
  checkfirstnotification(userid){
    return this.httpService.get('checkfirstnotification/'+userid,null);
  }
  getcompletedtraining(userid){
    return this.httpService.get('getcompletedtraining/'+userid,null);
  }
  getartikkelit(postData){
    return this.httpService.post('artikkelit', postData);
  }
  gettiedotteet(postData){
    return this.httpService.post('tiedotteet', postData);
  }
  gettraining(id){
  	return this.httpService.get('training/'+id,null);
  }
  addfavouritetrainings(postData: any): Observable<any> {
    return this.httpService.post('favouritetrainings', postData);
  }
  sendcontact(postData: any): Observable<any> {
  	return this.httpService.post('sendemail', postData);
  }
  updateauthcode(postData: any): Observable<any> {
    return this.httpService.post('updateauthcode', postData);
  }
  deletenotification(postData: any): Observable<any> {
     return this.httpService.post('deletenotification', postData);
  }
  removefavouritetrainings(postData: any): Observable<any> {
  	return this.httpService.post('removefavouritetrainings', postData);
  }
  getsetting(userid){
    return this.httpService.get('getsetting/'+userid,null);
  }
  getactivecode(userid){
  	return this.httpService.get('getactivecode/'+userid,null);
  }
  getVideoConfig(url){
  	return this.httpService.getExternal(url,null);
  }
 
  updateSetting(postData: any): Observable<any> {
  	return this.httpService.post('updatesetting', postData);
  }
  registerFcmtoken(postData: any): Observable<any> {
    return this.httpService.post('registerfcmtoken', postData);
  }
  videoCompleted(postData: any): Observable<any>{
    return this.httpService.post('videocompleted',postData);
  }
  login(postData: any): Observable<any> {
    return this.httpService.post('login', postData);
  }
  resendverification(postData: any): Observable<any> {
  	return this.httpService.post('resendverification', postData);
  }
  
  forgotpassword(postData: any): Observable<any> {
  	return this.httpService.post('forgotpassword', postData);
  }
  signup(postData: any): Observable<any> {
  	return this.httpService.post('register', postData);
  }
  removetoken(postData: any): Observable<any>{
    return this.httpService.post('removetoken', postData);    
  }
  updateBadge(postData: any): Observable<any>{
    return this.httpService.post('updatebadge', postData);    
  }
  logout() {
    this.storageService.removeStorageItem(AuthConstants.AUTH).then(res => {
    this.userData$.next('');
    this.router.navigate(['/kirjautuminen']);
    });
  }
}