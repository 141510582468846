import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomController } from '@ionic/angular';

interface Theme {
  styles: ThemeStyle[];
}

interface ThemeStyle {
  themeVariable: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcherService {
  private themes: Theme;

  constructor(private domCtrl: DomController, @Inject(DOCUMENT) private document) {
  }

  hexToRGBA(hex) {
    return (
      '' +
      (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      }) +
      ''
    );
  }

  setColor(primaryColor) {
    this.themes = {
      styles: [
        { themeVariable: '--ion-color-primary', value: primaryColor },
        { themeVariable: '--ion-color-primary-rgb', value: this.hexToRGBA(primaryColor) },
        // { themeVariable: '--ion-color-primary-contrast', value: '#ffffff' },
        // { themeVariable: '--ion-color-primary-contrast-rgb', value: '255,255,255' },
        // { themeVariable: '--ion-color-primary-shade', value: '#da3133' },
        // { themeVariable: '--ion-color-primary-tint', value: '#f94c4e' },
        // { themeVariable: '--ion-item-ios-background-color', value: '#ffffff' },
        // { themeVariable: '--ion-item-md-background-color', value: '#ffffff' },
        // { themeVariable: '--ion-tabbar-background-color', value: '#fff' },
        // { themeVariable: '--ion-tabbar-ios-text-color-active', value: '#000000' },
        // { themeVariable: '--ion-tabbar-md-text-color-active', value: '#000000' },
        // { themeVariable: '--ion-background-color', value: '#f94c4e' },
      ],
    };
  }

  setDefaultTheme() {
    this.themes = {
      styles: [
        { themeVariable: '--ion-color-primary', value: '#ca2d68' },
        { themeVariable: '--ion-color-primary-rgb', value: '202, 45, 104' },
        // { themeVariable: '--ion-color-primary-contrast', value: '#ffffff' },
        // { themeVariable: '--ion-color-primary-contrast-rgb', value: '255,255,255' },
        // { themeVariable: '--ion-color-primary-shade', value: '#da3133' },
        // { themeVariable: '--ion-color-primary-tint', value: '#f94c4e' },
        // { themeVariable: '--ion-item-ios-background-color', value: '#ffffff' },
        // { themeVariable: '--ion-item-md-background-color', value: '#ffffff' },
        // { themeVariable: '--ion-tabbar-background-color', value: '#fff' },
        // { themeVariable: '--ion-tabbar-ios-text-color-active', value: '#000000' },
        // { themeVariable: '--ion-tabbar-md-text-color-active', value: '#000000' },
        // { themeVariable: '--ion-background-color', value: '#f94c4e' },
      ],
    };
    this.domCtrl.write(() => {
      this.themes.styles.forEach((style) => {
        document.documentElement.style.setProperty(style.themeVariable, style.value);
      });
    });
  }

  setTheme(primaryColor): void {
    this.setColor(primaryColor);
    let theme = this.themes;
    this.domCtrl.write(() => {
      theme.styles.forEach((style) => {
        document.documentElement.style.setProperty(style.themeVariable, style.value);
      });
    });
  }
}
