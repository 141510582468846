import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeGuard } from './guards/home.guard';
import { IndexGuard } from './guards/index.guard';
import { UserDataResolver } from './resolvers/user-data.resolver';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'kirjautuminen',
    canActivate: [IndexGuard],
    resolve:{
    userData: UserDataResolver
    },
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'rekisteroidy',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'unohtunut-salasana',
    loadChildren: () => import('./pages/forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'koti',
    canActivate: [HomeGuard],
    resolve:{
    userData: UserDataResolver
    },
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'aula/:id',
    canActivate: [HomeGuard],
    loadChildren: () => import('./pages/lobby/lobby.module').then( m => m.LobbyPageModule)
  },
  {
    path: 'tiedotteet/:id',
    canActivate: [HomeGuard],
    loadChildren: () => import('./pages/tiedotteet/tiedotteet.module').then( m => m.TiedotteetPageModule)
  },
  {
    path: 'artikkelit/:id',
    canActivate: [HomeGuard],
    loadChildren: () => import('./pages/artikkelit/artikkelit.module').then( m => m.ArtikkelitPageModule)
  },
  {
    path: 'video/:id/:level',
    canActivate: [HomeGuard],
    loadChildren: () => import('./pages/video/video.module').then( m => m.VideoPageModule)
  },
  {
    path: 'palaute/:id',
    canActivate: [HomeGuard],
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'jatkomikrotreeni/:id',
    canActivate: [HomeGuard],
    loadChildren: () => import('./pages/jatkomikrotreeni/jatkomikrotreeni.module').then( m => m.JatkomikrotreeniPageModule)
  },
  {
    path: 'asetukset',
    loadChildren: () => import('./pages/setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'yhteys',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'koodi-asetukset',
    loadChildren: () => import('./pages/authcode-setting/authcode-setting.module').then( m => m.AuthcodeSettingPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules ,onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
