import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	constructor(public toastController: ToastController) {}

	async presentToast(infoMessage: string,cssclass:any='') {
		
		const toast = await this.toastController.create({
			message: infoMessage,
			duration: 2000,
	      	position: 'top',
	      	color: 'dark',
	      	cssClass:cssclass
		});
		toast.present();
	}
}