import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { environment } from './../../environments/environment';
import { Platform } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private auth: AuthService, private router: Router,private analyticsFirebase:FirebaseAnalytics,private platform: Platform,private titleService: Title) {
    /*this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      let pagename = e.url.split('?')[0];
      if(pagename.indexOf('/koti') >= 0){
        this.titleService.setTitle('Koti');
      }
      this.logEvent('screen_view',{page:e.url.split('?')[0]});
       console.log("lenth"+e.url.length);
      if(e.url.length > 100){
        let param = e.url.split('?')[1];
        let paramarr = param.split('&');
        let email = paramarr[1].split('=');
        console.log(email[1]);
         this.auth.getuserByemail(email[1]).subscribe(
          (res: any) => {
            if (res.status) {
              let userid = res.user;
            }
          }
        );
      }
      this.setScreenName(e.url)
    });*/
  }
  
  logEvent(arg1,arg2) {
    this.analyticsFirebase.logEvent(arg1,arg2);
  }
 
  setScreenName(screenName) {
    this.analyticsFirebase.setCurrentScreen(screenName);
  }
  
}