import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { }

    async setString(key: string, value: string) {
        await Storage.set({ key, value });
    }

    async getString(key: string): Promise<{ value: any }> {
        return (await Storage.get({ key }));
    }

    async setObject(key: string, value: any) {
        await Storage.set({ key, value: JSON.stringify(value) });
    }

    async getObject(key: string): Promise<{ value: any }> {
        const ret = await Storage.get({ key });
        return JSON.parse(ret.value);
    }
    // Store the value
    async store(storageKey: string, value: any) {
        const encryptedValue = btoa(escape(JSON.stringify(value)));
        await Storage.set({
        key: storageKey,
        value: encryptedValue
        });
    }

    // Get the value
    async get(storageKey: string): Promise<{ value: any }> {
    const ret = await Storage.get({ key: storageKey });
    if(ret.value){
        return JSON.parse(unescape(atob(ret.value)));    
    }else{
        return null;
    }
    
    }
    async removeStorageItem(storageKey: string) {
        await Storage.remove({ key: storageKey });
    }
    async removeItem(key: string) {
        await Storage.remove({ key });
    }

    async clear() {
        await Storage.clear();
    }
}