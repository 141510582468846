import { Component,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ToastService } from './services/toast.service';
import { StorageService } from './services/storage.service';
import { AuthConstants } from './config/auth-constants';
import { ThemeSwitcherService } from './services/themeswitcher.service';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { Badge } from '@ionic-native/badge/ngx';
import { Router } from '@angular/router';
 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  navigate : any;
  public authUser: any;
  activecode:any;
  companydetails:any;
  valid_date:any;
  device_token:any;
  constructor(
    private badge: Badge,
    private router: Router,
    private fcm: FCM,
    private platform: Platform,
    public themeSwitcher: ThemeSwitcherService,
    private toastService: ToastService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private auth: AuthService,
    public menuCtrl: MenuController,
    private storageService: StorageService) {
    this.storageService.get(AuthConstants.AUTH).then(res => {
      this.authUser = res;
      if(this.authUser && this.authUser.uid){
        this.getCompanyDetail(this.authUser.uid)
        this.getActivecode();
        this.sideMenu(); 
        
      }
    });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false)
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      if (this.platform.is('mobile')) {

        this.fcm.requestPushPermission().then(() => {});
        this.fcm.onNotification().subscribe(data => {
          if (data.wasTapped) {
            if(data.currentType=='artikkelit'){
                this.router.navigate(['artikkelit/'+data.id]);
              }else if(data.currentType=='tiedotteet'){
                this.router.navigate(['tiedotteet/'+data.id]);
              } else {
                this.router.navigate(['aula/'+data.id]);
              }
              this.storageService.get('fcmtoken').then(res => {
                this.auth.updateBadge({user_id:this.authUser.uid,device_token:res,action:'minus'}).subscribe((data:any) => {
                  let badgecounter = data.notification.badge_count;
                  this.badge.set(badgecounter);
                });
              });
          }
          
        });
        this.fcm.getInitialPushPayload().then(data => {
          if (data.wasTapped) {
            if(data.currentType=='artikkelit'){
                this.router.navigate(['artikkelit/'+data.id]);
              }else if(data.currentType=='tiedotteet'){
                this.router.navigate(['tiedotteet/'+data.id]);
              } else {
                this.router.navigate(['aula/'+data.id]);
              }
              this.storageService.get('fcmtoken').then(res => {
                this.auth.updateBadge({user_id:this.authUser.uid,device_token:res,action:'minus'}).subscribe((data:any) => {
                  let badgecounter = data.notification.badge_count;
                  this.badge.set(badgecounter);
                });
              });
          }
        });      
      }
    });
  }
  

  logout(){
    this.themeSwitcher.setDefaultTheme();
    if(this.platform.is('mobile')) {
      console.log("test");
    this.storageService.get('fcmtoken').then(res => {
      console.log("res=> "+res);
      this.removeToken(res);
    });
    }else{ 
      this.auth.logout();
    } 
  }

   removeToken(token:any)
  {
      this.auth.removetoken({user_id:this.authUser.uid,device_token:token}).subscribe(
      (res: any) => {
        if(res.status){
          this.auth.logout();
        }  
      },
      (error: any) => {
        this.toastService.presentToast('Jotain meni vikaan, ole hyvä ja yritä uudelleen.');
      },
    );
  }

  getActivecode(){
    this.auth.getactivecode(this.authUser.uid).subscribe(
      (res: any) => {
        if(res.status){
          this.activecode = res.codedetail;
          let valid_date;let today;
          if(this.activecode.schedule_type=='2'){
            this.valid_date = new Date(this.activecode.valid_date).getTime(); 
            today = new Date().getTime();
          }else if(this.activecode.schedule_type=='1'){
            this.valid_date = new Date(res.active_code.valid_date).getTime();
            today = new Date(this.activecode.created_at).getTime();
          }
          if(this.valid_date <today){
              this.logout();
          }
        }    
    },
    (error: any) => {
        this.toastService.presentToast('Something went wrong. Please try again.');
      },
    );
  }
  getCompanyDetail(uid) {
      if(!uid)
      return;
  
      this.auth.getcompanydetail(uid).subscribe(
        (res: any) => {
          if (res.status) {
            this.companydetails = res.company;
          }
        },
        (error: any) => {
          this.toastService.presentToast('Jotain meni vikaan, ole hyvä ja yritä uudelleen.');
        },
      );
    }
  toggleMenu() {
    this.menuCtrl.toggle();
  }
  sideMenu()
  {
    this.navigate =
    [
      {
        title : "Koti",
        url   : "/koti",
        icon  : "home"
      },
      {
        title : "Yhteydenotto",
        url   : "/yhteys",
        icon  : "people-outline"
      },
      {
        title : "Ilmoitusten hallinta",
        url   : "/asetukset",
        icon  : "settings-outline"
      },
    ]
  }
}
